.__Loadings_loading_screen {
    display:flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content:center;
    flex-wrap: wrap;
    font-family: 'SF-Bold';
    font-size: 1rem;
    
    .circle_red {
        animation-duration: 3s;
        animation-name: circle_red_opacity_animation;
        animation-iteration-count: infinite;
    }
    .circle_purple {
        animation-duration: 3s;
        animation-name: circle_purple_opacity_animation;
        animation-iteration-count: infinite;
    }
    h2 {
        margin-left: 0.3125em;
        color:$color-black-2;
    }
    p {
        font-size: 0.9em;
        font-family: 'Sf-Regular';
        color:$color-black-3;
    }
}
.__loding_text_long {
    display: block;
    width: calc(100% - 20px);
    height:10px;
    background:lighten( $color-black-4, 18% );
    border-radius:4px;
    margin: 5px 10px;

}
.__loding_text_short {
    display: block;
    width: 50%;
    height:10px;
    background:lighten( $color-black-4, 22% );
    border-radius:4px;
    margin: 5px 10px;
    

}
.__loading_image {
    display: block;
    background:lighten( $color-black-4, 22% );
    width:calc(100% - 20px);
    height: 200px;
    border-radius:$br-radius;
    margin:5px 10px;
}


// Spinner Variables
//********************************************************//
$size:       30px;
$speed:      0.75s;
$thickness:  4px; // Odd numbers created a "wobble" effect.


// Animation Keyframes
//********************************************************//
.__loading_button_bg {
    background:$color-primary-purple;
}
.__loading_wrapper_in_component {
    display: flex;
    width: 100%;
    height:100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction:column;
    p {
        font-size: 14px;
        color: $color-black-3;
        width: 100%;
        text-align: center;
    }
}
.__loading-spinner {
    display:block;
    width:20px;
    height: 20px;
  animation-duration:($speed);
  animation-iteration-count:(infinite);
  animation-name:(rotate-forever);
  animation-timing-function:(linear);
  border: $thickness solid $color-primary-purple;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}
.__loading_linear {
    display: block;
    width:350px;
    height:5px;
    background:rgba(128, 128, 128, 0.172);
    border-radius: $br-radius;
    position: relative;
    .__loading_linear_progress {
        display:block;
        width: 0%;
        height:100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(159,91,255);
        background: linear-gradient(338deg, rgba(159,91,255,1) 0%, rgba(132,91,255,1) 47%, rgba(99,91,255,1) 100%);
        transition: all 0.3s ease;
        border-radius: $br-radius;
    }
    .__loading_linear_progress_step {
        display: flex;
        align-items: center;
        justify-content: center;
        width:30px;
        height:30px;
        border-radius: 100%;
        background:#DFDFDF;
        position: absolute;
        top: calc(100% - 20px);
        p {
            font-style: 'SF-Bold';
        }

    }
}
@keyframes rotate-forever {
    0%    { transform:(rotate(0deg)); }
    100%  { transform:(rotate(360deg)); }
}
@keyframes circle_red_opacity_animation {
    from { fill:#303338 }
    90%  { fill:$color-primary-red }
    to   { fill:#303338 }
}
@keyframes circle_purple_opacity_animation {
    from { stroke:#828282   }
    90%   { stroke:$color-primary-purple  }
    to { stroke:#828282   }
}



    .__wrapper_alert {
        display: block;
        background:$always-white;
        min-width: 200px;
        max-width: 300px;
        height:auto;
        border-radius: $br-radius;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

        .__alert_header {
            padding:10px;
            border-bottom: 1px solid #DBDBDB;
            h3 {
                font-size:14px;
                font-family: 'SF-Bold';
            }
        }
        .__alert_body {
            padding: 10px;
            p {
                font-size:12px;
            }
            a {

                text-decoration: none;
                font-family:  "SF-Bold";
                color:$color-primary-purple;
                &:hover {
                    text-decoration: underline;
                }
            }
            hr {
                margin:10px 0;
            }
        }
        .__alert_footer {
            display: flex;
            width: 100%;
            height:40px;
            flex-flow: row wrap;
            flex-direction: row;
            border-top: 1px solid #DBDBDB;
            justify-content: center;
            align-items: center;
            // padding: 2px;
            
            .__btn {
                flex: 1 50%;
                border-radius: 0;
                background: $always-white;

                &:hover {
                    background:#dbdbdb4f;
                    color:$color-black-2;
                    box-shadow:0;
                }
            }
        }
    }
    .__alert_wrapper_in_form {
        display:block;
        width: 100%;
        padding:5px;
        background:$color-bg-alert-in-forms;
        border-left: 5px solid $color-primary-purple;
        border-radius: 0 $br-radius $br-radius 0;
        p {
            display: block;
            width: 100%;
            word-wrap: break-word;
        }
        & > p:nth-child( 1 ) {
            font-family: 'SF-Bold';
            font-size:12px;
            margin-bottom: 10px;
        }
        & > p:nth-child( 2 ) {
            font-family: 'SF-Regular';
            font-size: 12px;
        }
    }
    .__alert_error_inForm {
        background:$color-red-5;
        border-color: $color-red-1;
        & > p:nth-child( 1 ) {
            color:$color-red-1;
        }
        & > p:nth-child( 2 ) {
            color:$color-red-1;
        }
    }
    .__alert_success_inForm {
        background:$color-green-4;
        border-color: $color-black-2;
        & > p:nth-child( 1 ) {
            color:$color-red-1;
        }
        & > p:nth-child( 2 ) {
            color:$color-red-1;
        }
    }

.__modal_wrapper {
    display:flex;
    width: 100%;
    height:100vh;
    position:fixed;
    left:0;
    top:0;
    justify-content: center;
    align-items: center;
    z-index:5;
    background:rgba(0, 0, 0, 0.30);
    .__modal_container, .__modal_normal {
        display:block;
        position: relative;
        border-radius: $br-radius;
        .__btn_close {
            position: absolute;
            top:-40px;
            right:0;
            z-index: 5;
            padding:8px;
        }
    }
    .__modal_normal {
        background:$always-white;
        width:40vw;
        height:80vh;
        & > div {
            width: 100%;
            height:100%;
            overflow: hidden;
        }
        h1 {
            position: relative;
            color:$color-black-1;
            font-size: 1.3em;
            font-family: 'SF-Bold';
            border-bottom: 1px solid #DBDBDB;
            padding: 10px;
        }
        h3 {
            font-family: 'SF-Bold';
            color:$color-black-2;
            font-size: 1.1em;

        }
        form {
            padding: 10px;
            overflow-y: auto;
            width: 100%;
            height: 100%;
            color: $color-black-2;
            p {
                font-family: 'SF-Bold';
                font-size: 12px;
                color: $color-black-2;
            }
            
        }
        .__modal_body {
            display: flex;
            width: 100%;
            height: calc(100% - 110px);
            justify-content:center;
            align-items: center;
            flex-flow: column wrap;
            min-height: 100px;
            padding: 0 10px;
            & > svg {
                // margin: 0 25%;
                width:50%;
                height:auto;
            }
            strong {
                color:$color-primary-purple;
                font-family: 'SF-Bold';
                font-size: 14px;
            }
            & > p {
                font-size: 14px;
            }
            & > .__btn {
                margin: 15px 0;
                width: 50%;
            }
            ol {
                font-family: 'SF-Bold';
                font-size:14px;
                li {
                    padding: 5px;
                }
            }
        }
        .__modal_footer {
            display: flex;
            justify-content: space-evenly;
            width:100%;
            height:45px;
            button {
                width:40%;
                height:100%;
            }

        }
        & > div > img {
            position: relative;
            width:100%;
            height:100%;
            object-fit: cover;
            border-radius: $br-radius;
            font-size: 1em;
            position:absolute;
            left: 0;
            top:0;
        }
        .titleImage {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 50px;
            z-index: 1;
            -webkit-backdrop-filter: blur(3px); /* Safari 9+ */
            backdrop-filter: blur(3px); /* Chrome and Opera */
            background-color: rgba(0, 0, 0, 0.4);
            color:$always-white;
            border-radius:  8px 8px 0 0;
            display:flex;
            align-items: center;
            padding: 10px;
        }
        ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            height:100%;
            overflow-y: auto;
            li:nth-child(2n) {
                background: #fafafa
            }
            li {
                padding: 10px;
                width: 100%;
                height: 55px;
                font-size:14px; 
                transition: all 0.3s ease;
                img {
                     width: 40px;height: 40px;
                }
                &:hover {
                    background:$color-blue-4;
                }
            }
        }
    }    
}
.__modal_submenu_event {
    width:220px;
    height: auto;
    display: block;
    background:$color-button-primary;
    background:#EBEAEA;
    position: absolute;
    top:60px;
    right:10px;
    border-radius: $br-radius;
    box-shadow: $shadows-elements;
    list-style: none;
    z-index: 1;
    overflow:hidden;
    li {
        display:flex;
        flex-direction: row;
        overflow:hidden;
        .__btn {
            background:transparent;
            width:100%;
            justify-content:left;
            border-radius: 0;
            &:hover {
                background:$color-black-2;
                svg {
                    path, rect, circle {
                        stroke: $always-white;
                    }
                }
            }
            p {
                margin-left: 5px;
            }
        }
    }
    // &::before {
    //     content:'';
    //     position: absolute;
    //     top: -20px;
    //     right:10px;
    //     width: 0;
    //     height: 0;
    //     border-right: 10px solid transparent;
    //     border-top: 10px solid transparent;
    //     border-left: 10px solid transparent;
    //     border-bottom: 10px solid #EBEAEA;

    // }
}
@include medium {
    .__modal_wrapper {

        .__modal_normal {
            width:calc(100vw - 80px);
            height:calc(100vh -  80px);
            img {
                width:100%;
                height:100%;
            }
        }
    }
}
@include small {
    .__modal_wrapper {

        .__modal_normal {
            width:calc(100vw - 60px);
            height:80vh;
        
            img {
                width:100%;
            }
        }
    }
}



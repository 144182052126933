.__input_wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 40px;
    font-size: 1em;
    margin: 10px 0;
    & > svg {
        display: block;
        width:20px;
        height: 20px;
        position:absolute;
        left:0;
        top:0;
        margin-top: calc(40px - 30px);
        margin-left: 5px;
        color:$color-black-4;
        // fill: transparent;
    }
    .__input {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid $color-black-4;
        position: relative;
        outline:0;
        font-family: 'SF-Regular';
        font-size: 14px;
        transition: all 0.3s ease;
        padding-left: 5px;
        &:focus {
            border: 2px solid $color-primary-purple;
            box-shadow: $shadow-purple-inputs;
            font-size: 16px;
            
        }
        &:focus~svg{
            color:$color-primary-purple;
            // fill:$color-primary-purple;
            path {

                stroke:$color-primary-purple;
            }
        }
        
    }
    .__input_with_icon {
        padding: 5px 5px 5px 35px;
    }
    .__btn_password {
        height:100%;
        position:absolute;
        right:2px;
        top:0;
        background: transparent;
        color:$color-black-3;
        &:hover {
            color:$color-primary-purple;
        }
    }
    .flag {
            position:absolute;
            left: 0;
            top:0px;
            margin-right: 5px;
            width: 30px;
            height:25px;
            border-radius: 5px;
        
    }
}
.__input_code {
    width: 55px;
    height: 40px;
    margin-inline:5px;
    border-radius: 4px;
    border: 1px solid $color-black-4;
    position: relative;
    outline:0;
    font-family: 'SF-Regular';
    font-size: 12px;
    transition: all 0.3s ease;
    padding: 5px;
    text-align: center;
    &:focus {
        font-family: 'SF-Bold';
        color: $color-black-2;
        border: 2px solid $color-primary-purple;
        box-shadow: $shadow-purple-inputs;
        font-size: 16px;
        
    }

}
.__input_calendar {
    width: 100%;
    height: 40px;
    position: relative;
    & > div {
        border-radius: 4px;
        padding: 5px;
        background:$always-white;
        border-color: #DBDBDB;
    }
}
.react-datetime-picker__calendar .react-datetime-picker--open {
    border: 2px solid $color-primary-purple;
    border-radius: 6px;
    .react-calendar{
        margin-top:5px;
        margin-left: -3px;
        padding: 5px;
        border: 0;
        border-radius: $br-radius;
        -webkit-backdrop-filter: blur(10px); /* Safari 9+ */
        backdrop-filter: blur(10px); /* Chrome and Opera */
        background-color: rgba(219, 219, 219, 0.09);
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        .react-calendar__tile {
            padding: 0.75em;
            height:50px;
            border-radius:100%;
        }
        .react-calendar__tile--active {
            background-color:$color-primary-purple;
            color:$always-white;
            border-radius:100%;
        }

    }
    
}
.__input_file {
    width: 100%;
    height: 80px;
    input#file-upload-button {
        display: none;
    }

}
textarea {
    width: 100%;
    height:100px;
    margin-top: 10px;
    background: $color-blue-6;
    border-radius: $br-radius;
    background:$always-white;
    resize: none;
    border: 1px solid $color-black-4;
    outline: none;
    padding:10px;
    font-size: 12px;
    font-family: 'SF-Regular';
    color: $color-black-1;
    transition: all 0.3s ease-in-out;
    &:focus {
        border: 2px solid $color-primary-purple;
        box-shadow: $shadow-purple-inputs;
        font-size: 16px;
        
    }
}
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}
.__input_switch {
    display: flex;
    width:100%;
    height:50px;
    flex-flow: row wrap;
    align-items: center;
    p {
        margin-right: 5px;
    }
}
.switch-container{
    input[type="checkbox"]{
      display: none;
    }
    width:40px;
    height: 20px;
    .switch-label{
      position: relative;
      cursor: pointer;
      .switch-rail{
        transition: all 0.2s ease-in-out;
        position: absolute;
        width: 40px;
        height: 22px;
        background: #DBDBDB;
        border-radius: 50px;
        overflow: hidden;
        .switch-slider{
          transition: all 0.2s ease-in-out;
          position: absolute;
          top: 2.5px;
          left: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #fff;
        }
      }
    }
    input[type="checkbox"]:checked + .switch-label {
      .switch-rail{
        transition: all 0.2s ease-in-out;
        background: #38c172;
        .switch-slider{
          transition: all 0.2s ease-in-out;
          transform: translateX(15.5px);
        } 
      }        
    }
  }
  .__input_radio {
      display: flex;
      height: 100%;
      justify-content:center;
      align-items: center;
      font-size:14px;
      label {
          width: 100%;
          height: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content:center;
          align-items: center;
        //   padding: 10px;
          transition: all 0.3s ease;
          border-bottom: 5px solid trasparent;
          svg {
              width: 18px;
              height: 18px;
          }
          &:hover {
              color: $color-primary-purple;
              cursor: pointer;
              font-family: 'SF-Bold';
              border-bottom: 5px solid $color-primary-purple;
          }

      }
      input[type="radio"] {
          display: none;
          &:active {
              &+label {
                color: $color-primary-purple;
                font-family: 'SF-Bold';
                border-bottom: 5px solid $color-primary-purple;
              }
          }
          &:checked {
              &+label {
                color: $color-primary-purple;
                font-family: 'SF-Bold';
                border-bottom: 5px solid $color-primary-purple;
              }
          }
      }


  }
._item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    font-size: 1em;
    position: relative;
    transition: all 0.3s ease;
    .badge_short {
        position: absolute;
        bottom:0.125em;
        left:2.5625em;
        padding:0.125em;
        font-size: 0.625em;
        border-radius: 0.625em;
        font-size: 0.65em;

    }
    .info-Contact {
        display: flex;
        width: calc(100% - 55px);
        height:100%;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 5px;
        font-size:1em;

        strong {
            font-size: 0.875em;
            font-family: 'SF-Bold';
            text-decoration: none;
            color:$color-black-1;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
        }
        p {
            font-size: 0.875em;
            font-family: 'SF-Regular';
            text-decoration: none;
            color:$color-black-3;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
            
        }
        
    }
    .__item_image_loading {
        display: block;
        width:50px;
        height:50px;
        border-radius:100%;
        background:lighten( $color-black-4, 18% );
    }
    ._item_name_loading, ._item_text_loading {
        display:block;
        height:10px;
        margin-bottom: 5px;
        border-radius:4px;
    }
    ._item_name_loading {
        width: calc(100% - 50px);
        background:lighten( $color-black-4, 18% );
    }
    ._item_text_loading {
        width: 50%;
        background:lighten( $color-black-4, 22% );
    }
    .__modal_info_user {
        display:block;
        width:200px;
        min-height: 100px;
        position: absolute;
        left: -210px;
        top:0;
        background:$always-white;
        z-index: 2;
        border-radius: $br-radius;
        box-shadow:$shadows-elements;
    }
}
.__itme_avatar {
        width: 3.125em;
        height: 3.125em;
        border-radius: 100%;
        object-fit: cover;
        image-rendering: pixelated;
        font-size: 1em;
        box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%,0.2)
}
._item_loading {
    animation: animation_loading_item 1.2s ease-in-out forwards infinite;
}
._item_sorces {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:60px;
    height: 55px;
    background: $always-white;
    border-radius: $br-radius;
    transition: all 0.3s ease;
    color:$color-black-2;
    text-decoration: none;
    margin-right: 10px;
    svg {
        width:20px;
        height:20px;
    }
    p {
        font-size: 10px;
    }
    &:hover {
        background: $color-black-1;
        color:$always-white;
        svg {
            animation: animation_rotate_element 1.8s linear infinite;
        }
    }
}
.badge_short {
    display: inline-block;
    width:auto;
    height:auto;
    padding: 0 10px 2px 10px;
    font-size: 16px;
    font-family: 'SF-Regular';
    border-radius: 20px;
    
}
.badge_color_blue {
    background:$color-blue-5;
    color:$color-blue-1;
}
.badge_asc {
    background:$color-asc;
    color:#FFF;
}
.badge_vol {
    background:$color-vol;
    color:#FFF
}
.cat-bienestar {
    background: $color-cat-bienestar;
    color:$always-white;
}
.cat-salud {
    background:$color-cat-salud;
     color:$always-white;
}
.cat-PM {
    background: $color-cat-PM;
     color:$always-white;
}
.cat-Ma {
    background: $color-cat-MediaAmb;
     color:$always-white;
}
.cat-Cul {
    background: $color-cat-Cultural;
     color:$always-white;
}
.cat-Dep {
    background: $color-cat-Dep;
     color:$always-white;
}
.cat-Ani {
    background: $color-cat-Anim;
     color:$always-white;
}
.__item_tootTip_asc_from_region {
    width: 350px;
    min-height: 100px;
    .__item_tootTip_asc_from_region_header {
        display: flex;
        width: 100%;
        height: 100px;
        flex-direction: row;
        align-items:center;
        & > img {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            margin-right:5px;
            box-shadow:none !important;
            cursor: pointer;
            transition: all 0.3s ease;
        }
        & > div {
            max-height:100px;
            strong {
                font-family: 'SF-Bold';
                width: calc(100% - 50px);
                font-size: 1.3em;
            }
            div {
                display: flex;
                width:100%;
                height:30px;
                flex-direction: row;
                align-items: center;
                margin-block:5px;
                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 2px;
                }
                p {
                    width: 200px;
                    font-size:12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                img {
                    width: 30px;
                    height: 30px;
                    border-radius:$br-radius;
                    margin-left: 5px;
                }
            }
            }
    }
}
@keyframes animation_loading_item {
    from {
        opacity: 0.5
        
    }
    to {
        opacity: 1
    }
}
@font-face {
    font-family: "SF-Bold";
    src: url('./fonts/SF-Pro-Display-Bold.otf') format("truetype");
}
@font-face {
    font-family: "SF-Semibold";
    src: url('./fonts/SF-Pro-Display-Semibold.otf') format("truetype");
}
@font-face {
    font-family: "SF-Medium";
    src: url('./fonts/SF-Pro-Display-Medium.otf') format("truetype");
}
@font-face {
    font-family: "SF-Regular";
    src: url('./fonts/SF-Pro-Display-Regular.otf') format("truetype");
}
@font-face {
    font-family: "SF-Light";
    src: url('./fonts/SF-Pro-Display-Light.otf') format("truetype");
}
@font-face {
    font-family: "SF-UltraLight";
    src: url('./fonts/SF-Pro-Display-Ultralight.otf') format("truetype");
}
@font-face {
    font-family: "SF-Thin";
    src: url('./fonts/SF-Pro-Display-Thin.otf') format("truetype");
}
*::-webkit-scrollbar,*::-webkit-scrollbar-button, body::-webkit-scrollbar,body::-webkit-scrollbar-button {
    background: transparent;
    width: 5px;
    height: 5px;
    
}
*::-webkit-scrollbar-thumb,*::-webkit-scrollbar-button, body::-webkit-scrollbar-thumb,body::-webkit-scrollbar-button {
    background: gray;
    border-radius: 2px;
    padding: 5px;
}

$bg_app:#F3F3F3;
$bg_menu:rgba(219, 219, 219, 0.35);
$filter-blur: blur(5px);
$color-saturaion:blur(5px);

$color-black-1:#1C1E21;
$color-black-2:#303338;
$color-black-3:#808080;
$color-black-4:#ADADAC;
$color-black-5: #f5f5f5;
$always-white: #FFFFFF;

$color-primary-red: #D65D38;
$color-primary-red-2: rgba(214, 93, 56, 0.50);
$color-primary-red-3: rgba(214, 93, 56, 0.40);
$color-primary-red-4: rgba(214, 93, 56, 0.20);
$color-primary-red-5: rgba(214, 93, 56, 0.10);

$color-primary-purple: #635BFF;
$color-primary-purple-2: rgba(99, 91, 255, 0.50);
$color-primary-purple-3: rgba(99, 91, 255, 0.40);
$color-primary-purple-4: rgba(99, 91, 255, 0.20);
$color-primary-purple-5: rgba(99, 91, 255, 0.10);

$color-asc: #8FC7FF;
$color-vol:#FFC45B;

$color-cat-bienestar:  #A9A8F6;
$color-cat-salud:  #2A4B7C;
$color-cat-PM:  #7F6C72;
$color-cat-MediaAmb:  #97EAB9;
$color-cat-Cultural:  #FF7F50;
$color-cat-Dep:  #FF6C6C;
$color-cat-Anim:  #B7BA26;

$color-blue-strong:#031d47;
$color-blue-1: #77A7FF;
$color-blue-2: hsla(219, 100%, 73%, 0.70);
$color-blue-3: hsla(219, 100%, 73%, 0.50);
$color-blue-4: hsla(219, 100%, 73%, 0.30);
$color-blue-5: hsla(219, 100%, 73%, 0.10);
$color-blue-6: hsla(219, 100%, 73%, 0.05);

$color-button-primary:#f2f2f2;

$color-red-1:#FF6C6C;
$color-red-2:rgba(255, 108, 108, 0.70);
$color-red-3:rgba(255, 108, 108, 0.50);
$color-red-4:rgba(255, 108, 108, 0.30);
$color-red-5:rgba(255, 108, 108, 0.10);
$color-green-4: #daffda;
$color-bg-alert-in-forms:#dbdbdb20;

$br-radius: 8px;
$shadows-elements:rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
$shadow-purple-elements:$color-primary-purple-3 0px 10px 10px -10px;
$shadow-purple-inputs:$color-primary-purple-4 0px 3px 12px;
$bg-button-favorite: #FFF1D9;
$color-button-favorite: #FFC45B;
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.bg_blur_effect_gray {
    -webkit-backdrop-filter: blur(10px); /* Safari 9+ */
    backdrop-filter: blur(10px); /* Chrome and Opera */
    background-color: rgba(255, 255, 255, 0.3);
}
.bg_blur_effect_black {
    -webkit-backdrop-filter: blur(10px); /* Safari 9+ */
    backdrop-filter: blur(10px); /* Chrome and Opera */
    background-color: rgba(0, 0, 0, 0.3);
}
.leaflet-container {
    width: 100%;
    height: 100%;
}
.markerComunidav {
    font-family: 'SF-Bold';
    &:hover {
        cursor: pointer;
    }
}
.mapComunidav {
    width: 100%;
    height: 500px;
    border-radius: 5px;
    .__wrapper_map_asc {
        padding: 10px;
        width:100%;
        height: 100%;
    }
}

@mixin small {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin medium {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin large {
    @media only screen and (max-width: 1440px) {
        @content;
    }
}


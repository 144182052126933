.__form_register {
    display: block;
    width: 370px;
    min-height: 300px;
    background:$always-white;
    border-radius: $br-radius;
    padding:10px;
    box-shadow: $shadows-elements;
    img {
        margin-left:calc(50% - 50px);
        width: 100px;
        height: 100px;
    }
    .__form_register_title {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        position: relative;
        // background:pink;
        span {
            position:absolute;
            color: $color-black-4;
            font-family: 'SF-Bold';
            font-size:14px;
            right: 0;
            top:0;
        }
        h1 {
            color: $color-black-2;
            font-size:16px;
            font-family: 'SF-Bold';
            position: relative;
        }
        & > p {
            display: block;
            font-family: 'SF-Regular';
            color: $color-black-3;
            font-size: 14px;
            width: 100%;
            padding-left: 85px;
            margin-top: 20px;
            position: relative;
        }
        .__btn {
            position:absolute;
            top:33px;
            left: 0;
            background: $always-white; 
            color:$color-primary-purple-2;
            z-index: 1;
            padding:4px;
            width: 75px;
            &:hover {
                color:$color-primary-purple;
                box-shadow:none;
            }
        }
    }
    .__form_register_wrapper_buttons_footer {
        display: flex;
        width: 100%;
        height: 40px;
        justify-content: flex-end;
        margin-top: 30px;
        
    }
}
.__form_button_generic_rfc {
    display: flex;
    width: 100%;
    height:30px;
    flex-direction: row;
    justify-content: flex-end;
    .__btn {
        background:transparent;
        font-size: 12px;
        &:hover {
            background:#DBDBDB;
            color: $color-black-2;
        } 
    }
}
.__form_wrapper_info_password {
    display: block;
    width: 100%;
    border-left: 5px solid $color-primary-purple;
    background:$color-primary-purple-5;
    padding:5px;
    border-radius: 0 $br-radius $br-radius 0;
    h5 {
        color: $color-black-2;
        font-family: 'SF-Bold';
        font-size: 14px;
    }
    p {
        font-size: 12px;
        margin: 5px 0;
    }

}
.__forms_txt_terms {
    font-size: 12px;
    color:$color-black-3;
    margin: 10px 0;
    a {
        font-size: 12px;
        color:$color-primary-purple;
        text-decoration:none;
        &:hover {
            font-weight:bold;
            cursor: pointer;
            text-decoration:underline;
        }
    }

}
@include small {
    .__form_register {
        width:100%;
        height:100vh;
        padding: 40px 10px;
    }
}
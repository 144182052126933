
@import './Bases/_bases';
@import './Buttons/buttons';
@import './Logos/logos_comunidav';
@import './Loadings/loadingScreen';
@import './NavBar/navbar.scss';
@import './Items/items.scss';
@import './Wrappers/wrappers';
@import './Modals/modal';
@import './SubMenu/_submenu';
@import './Inputs/input';
@import './Icons/icons';
@import './Messages/messages';
@import './Cards/cards';
@import './Forms/forms';
@import './Alerts/alerts';

body {
    position: relative;
    background:$bg_app;
    color:$color-black-1;
    font-family: 'SF-Regular';
}




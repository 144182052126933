.__submenu {

    display:flex;
    flex-direction: row;
    width: 100%;
    height: 2.5em;
    margin-block: 0.5em;
    overflow-y: hidden;
    overflow-x: auto;
    font-size: 16px;
    .__btn {
        background:$always-white;
        margin-left: 0.3125em;
        border-radius:3.125em;
        font-size: 0.8em;
        background-size: 700%;
        svg{
            width:1.5em;
            height: 1.5em;
            font-size:1em;
        }
        &:hover {
            background: rgb(159,91,255);
            background-image: linear-gradient(338deg, rgba(159,91,255,1) 0%, rgba(132,91,255,1) 47%, rgba(99,91,255,1) 100%);
            box-shadow: $shadows-elements;
            background-position: 50%;
        }
        &:active {
            background: $color-primary-purple;
        }
    }
    .__submenu_option_active {
        background: rgb(159,91,255);
        background: linear-gradient(338deg, rgba(159,91,255,1) 0%, rgba(132,91,255,1) 47%, rgba(99,91,255,1) 100%);
        box-shadow: $shadows-elements;
        color:$always-white;
        &:hover {
            background: $color-primary-purple;
        }
        // svg {
        //     fill:$always-white;
        //     path, rect {
        //         stroke:transparent;
        //     }
        // }
    }

}
.__submenu_bg_white {
    background:$always-white;
}
@include medium {
    .__submenu {
        font-size:14px;
    }

}
header {
    display:flex;
    position: relative;
    width: 100%;
    height: 3.4375em;
    backdrop-filter: blur(10px);
    background-color: $bg_menu;
    justify-content:space-between;
    align-items: center;
    font-size:16px;
    padding:5px;
    z-index: 1;
    position: sticky;
    top:0;
    .__Logo {
        text-decoration: none;
        color:$color-black-1;
        font-family: 'SF-Bold';
        font-size:1em;
    }
    nav {
        display:block;
        width:43%;
        height:100%;
        font-size: 1em;
        ul {
            display:flex;
            flex-direction: row;
            width: 100%;
            height:100%;
            list-style: none;
            .__navbar_option {
                display:flex;
                flex-direction: row;
                align-items: center;
                padding: 5px;
                width: 100%;
                height:100%;
                text-decoration: none;
                font-size: 1em;
                color:$color-black-1;
                position: relative;
                svg {
                    width: 1.60em;
                    height:1.60em;
                    font-size: 1em;
                }
                & > span {
                    display: block;
                    visibility: hidden;
                    width: 50%;
                    height: 5px;
                    background:$color-black-3;
                    border-radius: 10px;
                    position: absolute;
                    bottom:0;
                    margin: 0 25%;
                }
                &:hover > span {
                    visibility:visible;
                    background:$color-black-3;
                }
                
            }
            li {
                .__active_avatar {
                    cursor: pointer;
                    box-shadow: #DBDBDB 0px 0px 0px 3px, $color-black-3 0px 0px 0px 5px;

                }
                .__itme_avatar {
                    height:100%;
                    margin-left: 10px;
                    transition: all 0.3s ease;
                    &:hover {
                        cursor: pointer;
                        box-shadow: #DBDBDB 0px 0px 0px 3px, $color-black-3 0px 0px 0px 5px;

                    }
                }
                .__navbar_option_active {
                    color:$color-black-2;
                    font-family: 'SF-Bold';
                    transition: all 0.3s ease;
                    span {
                        visibility:visible;
                        background:$color-black-2;
                    }
                    svg {
                        // fill: $color-black-1;
                        stroke:$color-black-2;
                        fill:$color-black-2;
                        .__path_on{
                            stroke: #DBDBDB;
                            fill:transparent
                            
                        }
                        circle {
                            fill: $color-black-2;
                            stroke:$color-black-2;
                        }
                    }
                }
            }
        }
    }
}
.__navbar_mobile {
    display:none;
    width: 100%;
    height: 3.4375em;
    backdrop-filter: blur(10px);
    background-color: $bg_menu;
    nav {
        display:block;
        width:43%;
        height:100%;
        font-size: 1em;
        ul {
            display:flex;
            flex-direction: row;
            width: 100%;
            height:100%;
            list-style: none;
            .__navbar_option {
                display:flex;
                flex-direction: row;
                align-items: center;
                padding: 5px;
                width: 100%;
                height:100%;
                text-decoration: none;
                font-size: 1em;
                color:$color-black-1;
                position: relative;
                svg {
                    width: 1.60em;
                    height:1.60em;
                    font-size: 1em;
                }
                & > span {
                    display: block;
                    visibility: hidden;
                    width: 50%;
                    height: 5px;
                    background:$color-black-3;
                    border-radius: 10px;
                    position: absolute;
                    bottom:0;
                    margin: 0 25%;
                }
                &:hover > span {
                    visibility:visible;
                    background:$color-black-3;
                }
                
            }
            li {
                .__active_avatar {
                    cursor: pointer;
                    box-shadow: #DBDBDB 0px 0px 0px 3px, $color-black-3 0px 0px 0px 5px;

                }
                .__itme_avatar {
                    height:100%;
                    width: auto;
                    margin-left: 10px;
                    transition: all 0.3s ease;
                    &:hover {
                        cursor: pointer;
                        box-shadow: #DBDBDB 0px 0px 0px 3px, $color-black-3 0px 0px 0px 5px;

                    }
                }
                .__navbar_option_active {
                    color:$color-black-2;
                    font-family: 'SF-Bold';
                    transition: all 0.3s ease;
                    span {
                        visibility:visible;
                        background:$color-black-2;
                    }
                    svg {
                        // fill: $color-black-1;
                        stroke:$color-black-2;
                        fill:$color-black-2;
                        .__path_on{
                            stroke: #DBDBDB;
                            fill:transparent
                            
                        }
                        circle {
                            fill: $color-black-2;
                            stroke:$color-black-2;
                        }
                    }
                }
            }
        }
    }
}
@include small {
    header {
        font-size:14px;
        position: fixed !important;
        z-index: 1;
        nav {
            width:20%;
        }
    }
    ._navbar_option_responsive {
        display:none;
    }
    .__navbar_mobile {
        display:block;
        position: fixed;
        bottom:0;
        z-index: 1;
        font-size:14px;
        position: fixed;
        bottom:0;
        nav {
            width: 100%;
            height: 100%;
            ul {
                display:flex;
                justify-content:center;
                align-items:center;
                width:100%;
                height:100%;
                // background:pink;
                li {
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    flex-direction:column;
                }
            }
        }
    }


}